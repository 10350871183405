import { FelaCSS } from '../../../components/fela/flowtypes';
import { colors } from '../../../themes/variables';

interface IStyles {
  tooltipButtonWrap: FelaCSS;
  tooltipButtonLabelWrap: FelaCSS;
  tooltipButtonLabel: FelaCSS;
  tooltipButtonValue: FelaCSS;
  chevronWrap: (isOpened: boolean) => FelaCSS;
}

const styles = (): IStyles => ({
  tooltipButtonWrap: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    cursor: 'pointer',
  },

  tooltipButtonLabelWrap: {
    flexDirection: 'column',
  },

  tooltipButtonLabel: {
    color: colors.space60,
    fontDefault: 10,
  },

  tooltipButtonValue: {
    color: colors.space,
    fontDefault: 14,
  },

  chevronWrap: (isOpened) => ({
    marginBottom: '7px',
    marginLeft: '8px',
    transform: isOpened ? 'rotate(180deg)' : 'none',
  }),
});

export default styles;
