import Router from 'next/router';
import { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { getCountryCodeByAppLanguage } from 'lib/app/selectors';
import { getAdmin, getUser } from 'lib/hoc/utils';
import { PageInitialProps } from 'lib/types';
import { UrlHelper } from 'lib/url-helper';

/**
 * Countries where self-signup is allowed
 * If it isn't, then registrations are handled by the sales team.
 */
export const SELF_SIGNUP_COUNTRIES: CountryCodes[] = [
  CountryCodes.GB,
  CountryCodes.DE,
  CountryCodes.FR,
  CountryCodes.IE,
];

export const getAuthInitialProps = async ({
  ctx: { req, res, asPath },
  store,
}: PageInitialProps) => {
  const [user, isAdmin] = await Promise.all([getUser(req, res, store), getAdmin(req, res, store)]);
  const state = store.getState();

  if (user && res) {
    res.writeHead(302, {
      Location: isAdmin ? UrlHelper.admin.search : UrlHelper.dashboard,
    });
    return res.end();
  }

  const countryCode = getCountryCodeByAppLanguage(state);
  const isSignupAllowed = SELF_SIGNUP_COUNTRIES.includes(countryCode);

  if (asPath === UrlHelper.auth.signUp && !isSignupAllowed) {
    if (res) {
      // SSR
      res.writeHead(302, {
        Location: UrlHelper.auth.login,
      });
      return res.end();
    } else {
      // Client
      Router.replace(UrlHelper.auth.login);
    }
  }
};
