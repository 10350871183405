import { FelaCSS } from '../../../components/fela/flowtypes';
import { colors, spacing } from '../../../themes/variables';
import { TOOLTIP_BORDER_RADIUS, TOOLTIP_INNER_PADDING } from '../tooltip-v2/tooltip-v2.style';

interface IStyles {
  wrapper: FelaCSS;
  itemWrap: (props: { isSelected: boolean; isInteractive: boolean }) => FelaCSS;
  iconWrap: FelaCSS;
  content: FelaCSS;
  title: FelaCSS;
}

// Border radius of tooltip container minus inner padding, for accurate radius effect
const ITEM_BORDER_RADIUS = TOOLTIP_BORDER_RADIUS - TOOLTIP_INNER_PADDING;

export const styles: IStyles = {
  wrapper: {
    gap: spacing[1],
    padding: spacing[1],
  },

  itemWrap: ({ isSelected, isInteractive }) => ({
    flexShrink: 0,
    flexDirection: 'row',
    alignItems: 'center',
    padding: 2,
    gap: spacing[2],

    ...(isSelected
      ? {
          fontDefaultSemiBold: 'inherit',
          background: colors.space04,
          borderRadius: ITEM_BORDER_RADIUS,
        }
      : { fontDefault: 'inherit' }),

    ...(isInteractive && {
      cursor: 'pointer',

      ':hover': {
        background: colors.space04,
        borderRadius: ITEM_BORDER_RADIUS,
      },
    }),
  }),

  iconWrap: {
    width: 18,
    height: 18,
    justifyContent: 'center',
    alignItems: 'center',
  },

  content: {
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
    fontDefault: 14,
    lineHeight: '18px',
    color: colors.space,
  },

  title: {
    color: colors.space,
    fontDefaultSemiBold: 12,
  },
};
