import Link from 'next/link';
import React, { FC, PropsWithChildren } from 'react';
import { A, Box, LoadingCircle } from '@bridebook/ui';
import { CountrySelect } from 'components/country-select/country-select';
import BridebookLogoBusiness from 'components/svg/bridebook-logo-business';
import { getSupplierCountryCode } from 'lib/app/selectors';
import { getUrlHelperLandings } from 'lib/url-helper-landings';
import { useAppSelector } from 'lib/utils';
import componentStyles from './auth.style';

const Wrapper = ({ children }: PropsWithChildren) => {
  const countryCode = useAppSelector(getSupplierCountryCode);

  const styles = componentStyles();
  return (
    <Box style={styles.wrapper}>
      <Box style={styles.countrySelect}>
        <CountrySelect />
      </Box>
      <Link href={getUrlHelperLandings(countryCode).home} passHref legacyBehavior>
        <A>
          <BridebookLogoBusiness theme="light" />
        </A>
      </Link>
      {children}
    </Box>
  );
};

const Content: FC<PropsWithChildren> = ({ children }) => {
  const styles = componentStyles();
  return <Box style={styles.content}>{children}</Box>;
};

const LoadingOverlay = ({ show }: { show: boolean }) => {
  if (!show) return null;
  const styles = componentStyles();
  return (
    <Box style={styles.loadingOverlay}>
      <LoadingCircle size={40} color="space" animate />
    </Box>
  );
};

const Footer: FC<PropsWithChildren> = ({ children }) => {
  const styles = componentStyles();
  return <Box style={styles.bottomSwitch}>{children}</Box>;
};

export const Auth = {
  Wrapper,
  Content,
  Footer,
  LoadingOverlay,
};
