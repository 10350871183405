import { mergeDeepRight } from 'ramda';
import React, { ReactNode } from 'react';
import { Box, FelaCSS, TColor } from '@bridebook/ui';
import { IconChevron } from '@bridebook/ui/src/icons';
import { IStylingProps } from '../../../themes/types';
import componentStyles from './tooltip-list-trigger.style';

interface IProps extends IStylingProps {
  label?: string;
  value: string | ReactNode;
  isOpened: boolean;
  style?: FelaCSS;
  iconColor?: TColor;
}

const TooltipListTrigger = ({
  label,
  value,
  isOpened,
  style = {},
  iconColor = 'space60',
  ...restProps
}: IProps) => {
  const styles = componentStyles();

  return (
    <Box style={mergeDeepRight(styles.tooltipButtonWrap, style)} {...restProps}>
      <Box style={styles.tooltipButtonLabelWrap}>
        {label && <Box style={styles.tooltipButtonLabel}>{label}</Box>}
        <Box style={styles.tooltipButtonValue}>{value}</Box>
      </Box>
      <IconChevron width={10} color={iconColor} style={styles.chevronWrap(isOpened)} />
    </Box>
  );
};

export default TooltipListTrigger;
