import React, {
  ChangeEventHandler,
  MouseEventHandler,
  ReactNode,
  SyntheticEvent,
  useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ValidationError } from '@bridebook/toolbox/src';
import { Box, ButtonV2, H4, InputPassword, InputV2, Well } from '@bridebook/ui';
import { LinkText } from 'components/i18n/link-text';
import IconLightbulb from 'components/svg/icon-lightbulb';
import { signInWithPassword } from 'lib/auth/actions';
import { getAuthFormError } from 'lib/auth/selectors';
import { IAuthFields } from 'lib/auth/types';
import { UrlHelper } from 'lib/url-helper';
import { useAppSelector } from 'lib/utils';
import componentStyles from './login.style';

const defaultFields: IAuthFields = {
  email: '',
  password: '',
};

const Login = () => {
  const [fields, _setFields] = useState<IAuthFields>(defaultFields);
  const { t } = useTranslation('loggedOut');
  const authFormError = useAppSelector(getAuthFormError);
  const dispatch = useDispatch();

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    _setFields({
      ...fields,
      [e.target.name]: e.target.value,
    });
  };

  const onFormSubmit = (e: SyntheticEvent) => {
    if (e) e.preventDefault();
    dispatch(signInWithPassword(fields));
  };

  const onKeyUp = (e: React.KeyboardEvent<Element>) => {
    if (e.key === 'Enter') {
      onFormSubmit(e);
    }
  };

  const validationError =
    authFormError instanceof ValidationError && authFormError.prop ? authFormError : null;

  const emailError = (authFormError as ValidationError)?.prop === 'email';

  const styles = componentStyles();
  return (
    <form onSubmit={onFormSubmit} noValidate method="post" onKeyUp={onKeyUp}>
      <Box mb={6}>
        <H4>{t('logInToBridebook')}</H4>
      </Box>
      <Box mb={4}>
        <InputV2
          dataTest="email"
          name="email"
          onChange={onChange}
          type="email"
          required
          error={validationError}
          placeholder={t('inputText.emailAddress.placeholder')}
          value={fields.email}
        />
      </Box>

      <Box mb={4}>
        <InputPassword
          dataTest="password"
          name="password"
          onChange={onChange}
          required
          error={validationError}
          placeholder={t('inputText.password.placeholder')}
          value={fields.password}
        />
      </Box>

      {validationError && emailError && (
        <Box mb={4}>
          <Well theme="peachRose">
            <Box style={styles.emailSuggestion}>
              <Trans ns="loggedOut" i18nKey="findCorrectLogin">
                You can find your correct login email address at the bottom of your latest enquiry
                email.
              </Trans>
            </Box>
          </Well>
        </Box>
      )}

      <Box display="block">
        <LinkText
          href={UrlHelper.auth.forgotPassword}
          passHref
          style={styles.forgotPassword}
          bold={false}>
          {t('button.forgotPassword')}
        </LinkText>
      </Box>

      <ButtonV2
        dataTest="log-in-submit"
        onClick={onFormSubmit as unknown as MouseEventHandler<ReactNode>}>
        {t('common:button.logIn')}
      </ButtonV2>

      <Box style={styles.tipWrap}>
        <Box mr={4} justifyContent="center">
          <IconLightbulb color="peachRose120" width={30} />
        </Box>
        <Box display="inline">
          <Trans ns="loggedOut" i18nKey="logInTopTip">
            <Box as="span" style={{ fontDefaultSemiBold: 'inherit' }}>
              Top tip:
            </Box>
            The more business details you add, the higher you appear in couple search. We recommend
            10+ photos, 3+videos, supplier recommendations & pricing info!
          </Trans>
        </Box>
      </Box>
      {authFormError && !(authFormError as ValidationError).prop && (
        <Well theme="blushTangerine" mt={6}>
          {authFormError.message}
        </Well>
      )}
    </form>
  );
};

export default Login;
