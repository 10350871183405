import { FelaCSS, colors } from '@bridebook/ui';

interface IStyles {
  wrapper: FelaCSS;
  content: FelaCSS;
  loadingOverlay: FelaCSS;
  bottomSwitch: FelaCSS;
  countrySelect: FelaCSS;
}

const styles = (): IStyles => ({
  wrapper: {
    background: colors.space,
    minHeight: '100vh',
    paddingVertical: 12,
    paddingHorizontal: 4,
    justifyContent: 'center',
    alignItems: 'center',
  },

  content: {
    background: colors.white,
    padding: 8,
    borderRadius: 20,
    marginTop: 12,
    width: '400px',
    maxWidth: '100%',
    overflow: 'hidden',
  },

  loadingOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
    background: colors.white,
    zIndex: 100,
  },

  bottomSwitch: {
    color: colors.space04,
    marginTop: 12,
    display: 'block',
    fontDefaultSemiBold: 15,
  },

  countrySelect: {
    position: 'absolute',
    top: 20,
    right: 20,
  },
});

export default styles;
