import React, { FC, ReactNode, SyntheticEvent, memo, useMemo, useState } from 'react';
import {
  type TooltipOrientation,
  TooltipV2,
} from '@bridebook/ui/src/components/bbcommon/tooltip-v2/tooltip-v2';
import Box from '../../../components/fela/Box';
import { styles } from './tooltip-list.style';

export type TooltipListItem = {
  key: string;
  icon?: ReactNode;
  content: string | ReactNode;
  /**
   * Add a permanent selected background
   */
  isSelected?: boolean;
  /**
   * If not passed, then the item won't have a hover effect
   */
  onClick?: (e: SyntheticEvent<any>) => void;
};

interface IProps {
  /**
   * Array of tooltip list items
   */
  items: TooltipListItem[];
  /**
   * Render trigger element
   */
  render: (isOpened: boolean) => ReactNode;
  /**
   * Where to show the tooltip relative to the trigger element
   */
  orientation?: TooltipOrientation;
}

const TooltipListComp: FC<IProps> = ({ items = [], orientation = 'bottom', render }) => {
  const [isOpened, setIsOpened] = useState(false);

  const tooltipContent = useMemo(
    () => (
      <Box style={styles.wrapper}>
        {items.map(({ key, isSelected, icon, content, onClick }) => (
          <Box
            style={styles.itemWrap({ isSelected, isInteractive: !!onClick })}
            key={key}
            onClick={onClick}
            data-name={`owner-tooltip-${key}`}>
            {icon && <Box style={styles.iconWrap}>{icon}</Box>}
            <Box style={styles.content}>{content}</Box>
          </Box>
        ))}
      </Box>
    ),
    [items],
  );

  return (
    <TooltipV2
      type="click"
      theme="light"
      onToggle={(val) => setIsOpened(val)}
      customTrigger={render(isOpened)}
      noPadding
      minWidth={250}
      orientation={orientation}>
      {tooltipContent}
    </TooltipV2>
  );
};

export const TooltipList = memo(TooltipListComp);
