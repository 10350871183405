import { FelaCSS, colors } from '@bridebook/ui';

interface IStyles {
  header: FelaCSS;
  emailSuggestion: FelaCSS;
  forgotPassword: FelaCSS;
  tipWrap: FelaCSS;
}

const styles = (): IStyles => ({
  header: {
    fontDefaultSemiBold: 32,
    marginBottom: 6,
  },

  emailSuggestion: {
    fontDefault: 14,
  },

  forgotPassword: {
    fontDefaultSemiBold: 14,
    marginBottom: 8,
  },

  tipWrap: {
    marginTop: 6,
    fontDefault: 14,
    textAlign: 'justify',
    color: colors.space75,
    flexDirection: 'row',
  },
});

export default styles;
