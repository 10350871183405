import React from 'react';
import { FelaCSS, TColor } from '@bridebook/ui';
import IconWrap from '@bridebook/ui/src/components/svg/icon-wrap';

interface IProps {
  id?: string;
  color?: TColor;
  width?: number;
  style?: FelaCSS;
}

interface IconTypes {
  iconColor: string;
  width: number;
  height: number;
}

const IconLightbulb = ({ id, color, width = 23, style }: IProps) => {
  const viewBoxWidth = 993;
  const viewBoxHeight = 1024;

  const icon = ({ iconColor, width, height }: IconTypes) => (
    <svg
      {...{ id }}
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      width={`${width}px`}
      height={`${height}px`}>
      <path
        fill={iconColor}
        d="M617 617q-21 28-37 59t-19 70q-7 5-24.5 10.5t-33.5 5.5-34-5.5-25-10.5q-2-39-19.5-70t-35.5-59q-21-33-38.5-64.5t-17.5-77.5q0-79 48-127.5t125-48.5q76 0 124.5 50t48.5 126q-3 46-21 77.5t-41 64.5v0zM503 204q-58 0-106.5 20t-84 56-55.5 85-20 107q0 67 24.5 113.5t50.5 80.5q16 23 28 44t12 39q0 17 12 41t55 43q7 2 14 4.5t14 4.5q14 5 29 7t27 2q21 0 43-4.5t40-13.5q44-19 56-43t12-41q0-20 12-40t28-43q25-34 49.5-80.5t24.5-113.5q0-58-20-107t-55-85-83.5-56-106.5-20v0zM580 873q-19 9-36.5 12t-40.5 3-42.5-3-37.5-12q-19-7-36-1t-26 22q-7 19-1 36t22 26q10 5 20.5 9t20.5 7q6 2 14.5 2.5t15.5 3.5q0 4 2 10.5t5 10.5q9 17 27 22t34-6q12-7 17-16.5t5-20.5q18-3 35-8t36-14q18-7 24.5-25t-0.5-37q-4-16-22-23t-36 2v0zM503 336q-37 0-85 34t-48 117q0 17 10 29t24 15q2 0 6 1.5t6 1.5q19 0 33-14t14-33q0-41 20-49.5l20-8.5q18 0 32-14t14-33q0-18-14-32t-32-14v0zM799 271q-7 0-13.5-2.5t-11.5-9.5q-7-11-5-23.5t11-19.5l77-56q10-7 23.5-5t20.5 12q7 9 5 22.5t-12 20.5l-77 55q-4 3-9 4.5t-9 1.5v0zM870 796q-3 0-5.5-0.5t-7.5-2.5l-86-34q-11-5-17-17t-1-23q4-12 16-17.5t24-1.5l86 34q12 5 17.5 17t1.5 23q-3 9-11 15.5t-17 6.5v0zM962 524v0 0l-95-3q-14 0-22.5-9.5t-8.5-21.5q0-13 9.5-21.5t21.5-8.5l92 3q14 0 22.5 9.5t8.5 20.5q2 14-6 22.5t-22 8.5v0zM139 308h-9t-7-3l-80-49q-11-7-15-19.5t3-23.5q7-12 19-14t24 5l80 46q12 7 14 19t-5 24q-4 7-10.5 11t-13.5 4v0zM31 555q-9 0-17.5-6t-13.5-15q-2-12 4-23.5t18-13.5l89-25q12-2 23.5 4t13.5 17q2 12-4 23.5t-18 14.5l-86 24h-9zM154 824q-7 0-12-2.5t-9-7.5q-10-9-8.5-21.5t11.5-21.5l71-62q7-9 20-9t20 9q9 10 7.5 22t-10.5 22l-71 61q-3 5-7.5 7.5t-11.5 2.5zM358 142q-7 0-15-4.5t-13-11.5l-46-80q-7-11-3-23.5t15-19.5q9-5 22.5-2t17.5 14l46 81q7 11 3.5 23.5t-15.5 19.5q-2 2-6 2.5t-6 0.5v0zM620 142q-5 0-8-0.5t-7-2.5q-12-7-14.5-19.5t1.5-23.5l46-81q7-11 19.5-14t24.5 2q11 7 14 19.5t-2 23.5l-46 80q-5 10-13 13t-15 3v0z"
      />
    </svg>
  );

  return <IconWrap {...{ icon, color, width, viewBoxWidth, viewBoxHeight, style }} />;
};

export default IconLightbulb;
