import { getI18n } from 'react-i18next';

interface TLocaleConfig {
  name: string;
  locales: string[];
}

export const getLocaleNames = (): TLocaleConfig[] => [
  {
    name: getI18n().t('common:countryLanguage.UK'),
    locales: ['en-GB'] as string[],
  },
  {
    name: getI18n().t('common:countryLanguage.DE'),
    locales: ['de-DE', 'de-AT', 'de-CH'] as string[],
  },
  {
    name: getI18n().t('common:countryLanguage.FR'),
    locales: ['fr-FR'] as string[],
  },
  {
    name: getI18n().t('common:countryLanguage.IE'),
    locales: ['en-IE'] as string[],
  },
];
