import { CountryCodes, Gazetteer } from '@bridebook/toolbox/src/gazetteer';
import { findCountryCodeByLocale } from 'lib/i18n/utils';

interface IProps {
  countryCode?: CountryCodes;
  locale?: string;
  size?: number;
}

const CountryFlag = ({ locale, countryCode, size = 20 }: IProps) => {
  if (!countryCode && !locale) return null;

  const country = countryCode || (locale ? findCountryCodeByLocale(locale) : undefined);

  if (country == null) {
    return null;
  }

  const flagURL = Gazetteer.getCountryFlagImageURL(country);

  if (flagURL == null) {
    return null;
  }

  return <img src={flagURL} style={{ width: size }} />;
};

export default CountryFlag;
