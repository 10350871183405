import Head from 'next/head';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { colors } from '@bridebook/ui';
import { env } from 'lib/env';
import { ConnectedTypes, IApplicationState } from 'lib/types';
import { imgixBaseURL } from 'lib/utils';

const storeEnhancer = connect((state: IApplicationState) => ({
  isLiveOnCDN: state.app.device.isLiveOnCDN,
}));

interface IProps extends ConnectedTypes<typeof storeEnhancer> {
  title: string;
  description?: string | null;
  canonical?: string | null;
  ogTitle?: string | null;
  ogDescription?: string | null;
  ogImage?: string | null;
  ogType?: string | null;
  ogUrl?: string | null;
  robots?: string | null;
  plainTitle?: boolean;
  plainOgTitle?: boolean;
  // Adds no-index meta tag if true
  noIndex?: boolean;
  children?: ReactNode;
}

const isLive = env.LIVE;

const PageHead = ({
  title,
  description,
  canonical,
  ogTitle,
  ogDescription,
  ogImage,
  ogType,
  ogUrl,
  robots,
  children,
  plainTitle = false,
  plainOgTitle = false,
  isLiveOnCDN,
  noIndex = false,
}: IProps) => {
  const { t } = useTranslation('loggedOut');

  const defaultMeta = {
    description: t('metaDescription'),
    'og:description': t('metaDescription'),
    'og:title': t('supplierLoginArea'),
    'og:type': 'website',
    'og:image': `${imgixBaseURL}/assets/social/og-image-v1.png?auto=compress,format`,
  };

  const getTitle = (t: string | null) =>
    plainTitle ? t : t ? `${String(title)} | Supplier` : defaultMeta['og:title'];
  const pageTitle = plainTitle ? title : getTitle(title);
  const pageDescription = description || description === '' ? description : defaultMeta.description;
  const pageOgDescription = ogDescription || defaultMeta['og:description'];
  const pageOgImage = ogImage || defaultMeta['og:image'];
  const pageOgTitle = plainOgTitle ? ogTitle : getTitle(ogTitle ?? null);
  const pageOgType = ogType || defaultMeta['og:type'];
  const pageOgUrl = ogUrl || canonical;

  return (
    <Head>
      <title>{pageTitle}</title>
      <meta name="description" content={pageDescription} />
      {(!isLiveOnCDN || noIndex) && <meta name="robots" content="NOINDEX" />}
      {isLive && robots && <meta name="robots" content={robots} />}
      <meta property="og:title" content={pageOgTitle} />
      <meta property="og:description" content={pageOgDescription} />
      <meta property="og:type" content={pageOgType} />
      <meta property="og:locale" content="en_GB" />
      <meta property="og:image" content={pageOgImage} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      {canonical && isLiveOnCDN && <link rel="canonical" href={canonical} />}
      {pageOgUrl && <meta property="og:url" content={pageOgUrl} />}
      <meta property="og:site_name" content="Bridebook" />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:site" content="@bridebook" />
      <meta name="p:domain_verify" content="797eb482a41518e7a6dd4bd7f80c1297" />
      <meta name="theme-color" content={colors.space} />
      <meta name="referrer" content="always" />
      {children}
    </Head>
  );
};

export default storeEnhancer(PageHead);
