import React, { memo } from 'react';
import { getI18n, useTranslation } from 'react-i18next';
import { TooltipList, TooltipListTrigger } from '@bridebook/ui';
import { TooltipListItem } from '@bridebook/ui/src/components/bbcommon/tooltip-list/tooltip-list';
import CountryFlag from 'components/bbcommon/country-flag/country-flag';
import { getLocaleNames } from 'lib/i18n/data/locale-names';
import { useAppSelector } from 'lib/utils';

const CountrySelectComponent = () => {
  const { t } = useTranslation('loggedOut');
  const language = useAppSelector((state) => state.i18n.language);

  const closeTooltip = () => document.body.click();

  const setLocale = (language: string) => () => {
    getI18n().changeLanguage(language);
    closeTooltip();
  };

  const items: TooltipListItem[] = [
    {
      key: 'header',
      content: <b>{t('selectLanguage')}</b>,
    },
    ...getLocaleNames().map(({ name, locales }) => ({
      key: locales[0],
      content: name,
      icon: <CountryFlag locale={locales[0]} size={25} />,
      isSelected: locales.includes(language),
      onClick: setLocale(locales[0]),
    })),
  ];

  const selectedIcon = items.find((i) => i.key === language)?.icon || (
    <CountryFlag locale={getLocaleNames()[0].locales[0]} size={25} />
  );

  return (
    <TooltipList
      items={items}
      orientation="top-right"
      render={(isOpened) => (
        <TooltipListTrigger value={selectedIcon} isOpened={isOpened} iconColor="white" />
      )}
    />
  );
};

export const CountrySelect = memo(CountrySelectComponent);
